import {Injectable} from '@angular/core';
import {ToastComponent, ToastUtility} from "@syncfusion/ej2-angular-notifications";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  private showInMilliseconds = 5000;
  private toastObj: ToastComponent | null = null;

  constructor() {
  }

  public info(title: string, message: string): void {
    this.toastObj = ToastUtility.show({
      title: title,
      content: '<div class="d-flex align-items-center p-2"><span class="mts-icon px-1 fs-4"></span>' + message + '</div>',
      width: 'auto',
      timeOut: this.showInMilliseconds, //it in milliseconds, 0 to never close automatically
      animation: { show: { effect: 'SlideRightIn' }, hide: { effect: 'SlideRightOut' }},
      position: { X: 'Right', Y: 'Top' },
      newestOnTop: true,
      showCloseButton: false,
      cssClass: 'info',
    }) as ToastComponent;
  }

  public success(title: string, message: string): void {
    this.toastObj = ToastUtility.show({
      title: title,
      content: '<div class="d-flex align-items-center p-2"><span class="mts-icon px-1 fs-4"></span>' + message + '</div>',
      width: 'auto',
      timeOut: this.showInMilliseconds,
      animation: { show: { effect: 'SlideRightIn' }, hide: { effect: 'SlideRightOut' }},
      position: { X: 'Right', Y: 'Top' },
      newestOnTop: true,
      showCloseButton: false,
      cssClass: 'success',
    }) as ToastComponent;
  }

  public warning(title: string, message: string): void {
    this.toastObj = ToastUtility.show({
      title: title,
      content: '<div class="d-flex align-items-center p-2"><span class="mts-icon px-1 fs-4"></span>' + message + '</div>',
      width: 'auto',
      timeOut: this.showInMilliseconds,
      animation: { show: { effect: 'SlideRightIn' }, hide: { effect: 'SlideRightOut' }},
      position: { X: 'Right', Y: 'Top' },
      newestOnTop: true,
      showCloseButton: false,
      cssClass: 'warning',
    }) as ToastComponent;
  }

  public error(title: string, message: string): void {
    this.toastObj = ToastUtility.show({
      title: title,
      content: '<div class="d-flex align-items-center p-2"><span class="mts-icon px-1 fs-4"></span>' + message + '</div>',
      width: 'auto',
      timeOut: this.showInMilliseconds,
      animation: { show: { effect: 'SlideRightIn' }, hide: { effect: 'SlideRightOut' }},
      position: { X: 'Right', Y: 'Top' },
      newestOnTop: true,
      showCloseButton: false,
      cssClass: 'danger',
    }) as ToastComponent;
  }
}
