<form [formGroup]="fG">
  <div class="{{ style !== 'headless' ? 'e-float-input' : '' }} e-input-group e-float-icon-left {{ fG.controls[fCN].invalid && fG.controls[fCN].dirty ? 'e-error' : ''}} {{ iconClickType === 'both' ? 'hover-effect' : iconClickType === 'front' ? 'hover-effect-left' : ''}}">
    <!-- Front Icon Section -->
    <ng-container *ngIf="iconFront">
      <ng-container *ngIf="iconFrontTooltip">
        <ejs-tooltip opensOn='Click' content='Tooltip from click' class="e-input-group-icon mts-icon">
          {{ iconFront }}
        </ejs-tooltip>
      </ng-container>
      <ng-container *ngIf="!iconFrontTooltip">
        <span (click)="onIconFrontClicked()" class="e-input-group-icon mts-icon">{{ iconFront }}</span>
      </ng-container>
    </ng-container>

    <!-- Input Section with Close Icon and Dropdown -->
    <div class="e-input-in-wrap asterisk {{ iconClickType === 'back' ? 'hover-effect-right' : ''}}">
      <input *ngIf="style !== 'headless'" [type]="type" [formControlName]="fCN" (blur)="onBlur()" (keyup.enter)="onReturnKeyPress()" (input)="onInput($event)" class="e-input {{ cssClass }}" [attr.aria-placeholder]="placeholder ? placeholder : label" [attr.aria-labelledby]="label" />
      <input *ngIf="style === 'headless'" [type]="type" [formControlName]="fCN" (blur)="onBlur()" (keyup.enter)="onReturnKeyPress()" (input)="onInput($event)" class="e-input {{ cssClass }}" [placeholder]="placeholder" [attr.aria-placeholder]="placeholder ? placeholder : label" [attr.aria-labelledby]="label" />
      <span *ngIf="style !== 'headless'" class="e-float-line"></span>
      <label *ngIf="style !== 'headless'" [ngClass]="{'e-label-top': fG.controls[fCN].value !== '' && style === 'inline', 'e-label-bottom':  fG.controls[fCN].value == '' && style === 'inline'}" class="e-float-text">{{ label }}</label>

      <!-- Back Icon (Close Icon) -->
      <ng-container *ngIf="iconBack && (type !== 'search' || hasText)">
        <ng-container *ngIf="iconBackTooltip">
          <ejs-tooltip opensOn='Click' [content]="iconBackTooltip" class="e-input-group-icon mts-icon">
            {{ iconBack }}
          </ejs-tooltip>
        </ng-container>
        <ng-container *ngIf="!iconBackTooltip">
          <span (click)="onIconBackClicked()" class="e-input-group-icon mts-icon back-icon">{{ iconBack }}</span>
        </ng-container>
      </ng-container>

      <!-- Conditionally Render Dropdown List -->
      <div class="searchFilterDopdown">
      <ng-content></ng-content>
      </div>
    </div>
  </div>

  <div class="error-label" role="alert" *ngIf="hasErrorField">
    {{ errorText && fG.controls[fCN].invalid && fG.controls[fCN].dirty ? errorText : '' }}
  </div>
</form>
