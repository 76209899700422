import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingIndicatorSubject = new BehaviorSubject<number>(0);
  public loadingIndicator$ = this.loadingIndicatorSubject.asObservable();
  private loadingIndicator: number = 0;

  constructor() {
  }

  public setLoadingIndicator(action: string): void {
    if (action === 'increase') {
      this.loadingIndicator++;
    } else {
      this.loadingIndicator--;
    }
    this.loadingIndicatorSubject.next(this.loadingIndicator);
  }

  public getLoadingIndicator(): number {
    return this.loadingIndicator;
  }
}
