<ng-container *ngIf="style === 'e-btn'">
  <button ejs-button 
          [type]="type" 
          [class]="getButtonClass()" 
          [disabled]="(disabled) || (loadingService.loadingIndicator$ | async)" 
          [attr.aria-label]="text">
    <!-- Left Icon -->
    <span *ngIf="icon && iconPosition === 'left'" [class]="'mts-icon align-middle ' + iconClass">{{ icon }}</span>

    <!-- Button Content -->
    <span *ngIf="text" 
          [class.px-1]="icon || !icon">
      {{ text }}
    </span>

    <!-- Right Icon -->
    <span *ngIf="icon && iconPosition === 'right'" [class]="'mts-icon align-middle ' + iconClass">{{ icon }}</span>
  </button>
</ng-container>

<ng-container *ngIf="style === 'btn'">
  <button type="button" 
          class="btn lh-1" 
          [class]="cssClass" 
          [disabled]="(disabled) || (loadingService.loadingIndicator$ | async)" 
          [attr.aria-label]="text">
    <!-- Left Icon -->
    <span *ngIf="icon && iconPosition === 'left'" [class]="'mts-icon aling-webkit-middle ' + iconClass">{{ icon }}</span>

    <!-- Button Content -->
    <span *ngIf="text" 
          [class.px-1]="icon || !icon"  
          [class.aling-webkit-middle]="icon">
      {{ text }}
    </span>

    <!-- Right Icon -->
    <span *ngIf="icon && iconPosition === 'right'" [class]="'mts-icon aling-webkit-middle ' + iconClass">{{ icon }}</span>
  </button>
</ng-container>