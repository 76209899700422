import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {LocalStorageService} from "./local-storage.service";
import {CustomerService} from "./customer.service";
import {LanguageService} from "./language.service";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private currentTarget: string = '';

  constructor(
    private router: Router,
    private localstorageService: LocalStorageService,
    private customerService: CustomerService,
    private userService: UserService,
    private languageService: LanguageService,
  ) {
  }

  public navigateTo(target: string): void {
    this.router.navigate([target], {}).then(
      () => {
        console.log('Navigated to ' + target);
        this.setCurrentTarget(target);
      },
      (error) => {
        console.log('Error navigating to ' + target);
        console.error(error);
      }
    );
  }

  public navigateToStart(): void {
    const redirectAfterLoginPath = this.localstorageService.getLocalStorage('redirectAfterLoginPath');
    /*if (this.languageService.hasLanguageKey('LOGINNOTESHEADER') && !this.localstorageService.getLocalStorage('loginNotesAcceptedByID' + this.userService.getUser()!.id)) {
      this.navigateTo('/auth/login/notes');
    } else if (redirectAfterLoginPath !== null && redirectAfterLoginPath !== '/' && redirectAfterLoginPath !== '/auth/login' && redirectAfterLoginPath !== '/user-switch' && redirectAfterLoginPath.indexOf('/auth/customer') === -1) {
      this.navigateTo(this.localstorageService.getLocalStorage('redirectAfterLoginPath')!);
      this.localstorageService.removeItem('redirectAfterLoginPath');
    } else {
      let startPoint = '/dashboard';
      if (this.customerService.getCustomer()!.modules.setting_startup_redirect) {
        startPoint = this.customerService.getCustomer()!.modules.setting_startup_redirect;
      }
      if (this.userService.getUser()!.rights[startPoint.substring(1)]) {
        this.navigateTo(startPoint);
        return;
      }*/
      this.navigateTo('/profile');
    //}
  }

  public openURL(url: string): void {
    window.open(url, '_blank');
  }

  private setCurrentTarget(target: string): void {
    this.currentTarget = target;
  }

  public getCurrentTarget(): string {
    return this.currentTarget;
  }
}
