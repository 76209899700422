import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

import {TextBoxModule} from '@syncfusion/ej2-angular-inputs';
import {TooltipModule} from "@syncfusion/ej2-angular-popups";
import {DropDownListModule} from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'app-form-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass,
    TextBoxModule,
    TooltipModule,
    DropDownListModule
  ],
  templateUrl: './form-input.component.html',
  styleUrl: './form-input.component.scss'
})
export class FormInputComponent implements OnInit, OnChanges {

  @Input() type: 'text' | 'search' | 'password' = 'text';
  @Input() placeholder: string = '';
  @Input() fCN: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() label: string = '';
  @Input() errorText: string = '';
  @Input() iconFront: string = '';
  @Input() iconBack: string = '';
  @Input() iconClickType:  'none'|'front'|'back'|'both' = 'none';
  @Input() iconFrontTooltip: string = '';
  @Input() iconBackTooltip: string = '';
  @Input() style: 'inline'|'block'|'headless' = 'headless';
  @Input() disabled: boolean = false;
  @Input() maxChars: number = 0;
  @Input() cssClass: string = '';
  @Input() hasErrorField: boolean = true;

  @Output() blurred: EventEmitter<void> = new EventEmitter<void>();
  @Output() returnKeyPress: EventEmitter<void> = new EventEmitter<void>();
  @Output() iconFrontClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() iconBackClick: EventEmitter<void> = new EventEmitter<void>();

  public constructor() {
  }

  public hasText: boolean = false;

  public ngOnInit(): void {
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }

    if (this.maxChars) {
      this.fG.controls[this.fCN].valueChanges.subscribe((value: string) => {
        if (value.length > this.maxChars) {
          this.fG.controls[this.fCN].setValue(value.substring(0, this.maxChars));
        }
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public onReturnKeyPress(): void {
    this.returnKeyPress.emit();
  }

  public onIconFrontClicked(): void {
    if (this.iconClickType === 'front' || this.iconClickType === 'both') {
      this.iconFrontClick.emit();
    }
  }

  public onIconBackClicked(): void {
    // Clear the input and dropdown selection if the type is 'search'
    if (this.type === 'search') {
      this.fG.controls[this.fCN].setValue('');
      this.hasText = false;
    }
    if (this.iconClickType === 'back' || this.iconClickType === 'both') {
      this.iconBackClick.emit();
    }
  }

  public onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.hasText = inputElement.value.length > 0;
  }

  public onBlur(): void {
    if (!this.disabled) {
      this.blurred.emit();
    }
  }
}
